body {
  margin: 0;
  background-color: #14020c;
  font-family: "Orbitron", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
p,
li,
ul {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

input,
textarea {
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

button {
  border: none;
  padding: 0;
}
